<template>
  <div>
     
  </div>
</template>
<script>
// import md5 from 'md5'
  export default {
    methods: {
      goToCAD() {
      // let hashEmail = md5(this.userInfo.mail_address)
      // let hashId = md5(this.userInfo.employee_code)
        // if(this.userInfo.full_name){
        //       let hashEmail = md5(this.userInfo.mail_address)
        //       let hashId = md5(this.userInfo.emp_code?this.userInfo.emp_code:'')
        //       window.open(`https://cadrequestv2.hrd-s.com/login?index=${hashEmail}&id=${hashId}`, `_blank`)
        //       this.$router.push('/')
        //   }else if(this.userInfo.name){
        //       let hashEmail = md5(this.userInfo.email)
        //       let hashId = md5(this.userInfo.empcode)
        //       window.open(`https://cadrequestv2.hrd-s.com/login?index=${hashEmail}&id=${hashId}`, `_blank`)
        //       this.$router.push('/')
        //   }
        window.open(`https://cadrequestv2.hrd-s.com`, `_blank`)
        this.$router.push('/')
      } 
    },
    mounted () {
      this.goToCAD()
      
    },
  }
</script>

<style lang="scss" scoped>

</style>